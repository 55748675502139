import { useSession } from "@core/hooks/useSession";
import { api, transformAPIError } from "@core/services/nocd-api";
import { useQuery, UseQueryResult } from "react-query";

export const useCaseloadReviewNotifications = (): UseQueryResult<
  { notifications: number },
  Error
> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};

  return useQuery(
    ["caseload-review", "notifications", session?.user.email],
    ({ signal }) =>
      api
        .get<{ notifications: number }>(
          `/v1/clinicians/caseload_review/notifications?clinician_email=${session?.user.email}`,
          {
            headers: {
              Authorization: accessToken,
            },
            signal,
          }
        )
        .then(({ data }) => data)
        .catch(transformAPIError),
    {
      enabled: !!accessToken,
      staleTime: 1000 * 60 * 60, // 1 hour. Also expired manually when any action plan submitted
    }
  );
};

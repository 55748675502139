import { Box, Skeleton, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import * as React from "react";

import { QuestionsProps } from "../types";

const useStyles = makeStyles({
  container: {
    marginTop: "1rem",
    backgroundColor: "#fff",
    borderRadius: "0.5rem",
    border: "1px solid #e0e0e0",
    overflow: "hidden", // Ensures border radius applies to header as well
  },
  header: {
    padding: "0.75rem 1rem",
    fontWeight: "bold",
    color: "#1f2937",
    background: "linear-gradient(135deg, #D7C2F2, #A5B4FC)", // Purplish gradient
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  items: {
    padding: "1rem",
    lineHeight: "1.5",
  },
  questionList: {
    paddingLeft: "1.5rem", // Adds indentation for bullets
  },
  question: {
    marginBottom: "0.75rem",
    fontSize: "1rem",
    color: "#111827",
    listStyleType: "disc", // Ensures bullet points
  },
  lastQuestion: {
    border: "2px solid #A5B4FC",
    borderRadius: "0.375rem",
    padding: "0.5rem",
  },
});

export const Questions: React.FC<QuestionsProps> = ({
  questions,
  isLoading = false,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      {/* Header with gradient background */}
      <Box className={classes.header}>
        <Typography variant="h6">Questions to ask</Typography>
      </Box>

      {/* Bullet Pointed List */}
      <Box className={classes.items}>
        <Box component="ul" className={classes.questionList}>
          {isLoading && (
            <>
              <Skeleton variant="text" width="80%" />
              <Skeleton variant="text" width="60%" />
              <Skeleton variant="text" width="90%" />
            </>
          )}
          {!isLoading &&
            questions &&
            questions.length > 0 &&
            questions.map((question) => (
              <Box component="li" key={question} className={classes.question}>
                {question}
              </Box>
            ))}
          {!isLoading && (!questions || questions.length === 0) && (
            <Typography variant="body2" color="textSecondary">
              No updates since last session
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

import { Box, Skeleton, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import * as React from "react";

import { InsightCardProps } from "../types";

const useStyles = makeStyles({
  container: {
    padding: "0.5rem",
    marginBottom: "1rem",
    backgroundColor: "#f5f5f5",
    borderRadius: "0.375rem",
  },
  title: {
    display: "flex",
    gap: "0.5rem",
    alignItems: "center",
    fontWeight: "bold",
    color: "rgba(0, 0, 0, 0.9)",
  },
  items: {
    paddingLeft: "1.5rem",
    marginTop: "0.5rem",
    color: "rgba(0, 0, 0, 0.9)",
  },
});

export const InsightCard: React.FC<InsightCardProps> = ({
  icon,
  title,
  items,
  noItemsMessage,
  isLoading = false,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.title}>
        {icon}
        <Typography variant="body1">{title}</Typography>
      </Box>
      <Box component="ul" className={classes.items}>
        {isLoading && (
          <>
            <Skeleton variant="text" width="80%" />
            <Skeleton variant="text" width="60%" />
            <Skeleton variant="text" width="90%" />
          </>
        )}
        {!isLoading &&
          items &&
          items.length > 0 &&
          items.map((item) => (
            <Box component="li" key={item}>
              {item}
            </Box>
          ))}
        {!isLoading && (!items || items.length === 0) && (
          <Typography variant="body2" color="textSecondary">
            {noItemsMessage}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

import { useSession } from "@core/hooks/useSession";
import { api } from "@core/services/nocd-api";
import { AppointmentInsights } from "@features/appointment-insights-drawer/components/AppointmentInsights";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import { Button } from "@mui/material";
import React, { useRef, useState } from "react";
import { useQuery, UseQueryOptions } from "react-query";

interface SessionRecapResponse {
  insights: {
    session_notes_summary: string[];
    updates_since_last_session: string[];
    assessment_summaries: string[];
    questions: string[];
  };
}

export const fetchSessionRecap = async (
  appointmentId: number,
  accessToken: string
): Promise<SessionRecapResponse> => {
  const { data } = await api.get(
    `/v1/therapy/members/session_recap/${appointmentId}`,
    {
      headers: {
        Authorization: accessToken,
      },
    }
  );
  return data;
};

export const useSessionRecap = (
  appointmentId: number,
  options?: UseQueryOptions<SessionRecapResponse, Error>
) => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};

  return useQuery<SessionRecapResponse, Error>(
    ["session-recap", appointmentId, accessToken],
    () => fetchSessionRecap(appointmentId, accessToken),
    {
      enabled: appointmentId != null && !!accessToken,
      staleTime: Infinity,
      ...options,
    }
  );
};

const SessionRecapModal: React.FC<{ appointment_id: number }> = ({
  appointment_id,
}) => {
  const [open, setOpen] = useState(true);
  const buttonRef = useRef<HTMLButtonElement>(null);

  return (
    <div>
      <Button
        ref={buttonRef}
        variant="outlined"
        color="primary"
        onClick={() => setOpen(true)}
        sx={{
          color: "#4050B5",
          textTransform: "uppercase",
          fontWeight: "500",
          fontSize: "14px",
          height: "32px",
          minWidth: "275px",
          padding: "0 24px",
          borderRadius: "22px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 1.5,
          borderColor: "#4050B5",
          "&:hover": {
            backgroundColor: "transparent",
            borderColor: "#2F3C9E",
            color: "#2F3C9E",
          },
        }}
      >
        <AutoAwesomeOutlinedIcon fontSize="small" />
        View Session Prep
      </Button>
      <AppointmentInsights
        appointmentId={appointment_id}
        isOpen={open}
        onClose={() => setOpen(false)}
        drawerOffset={360}
      />
    </div>
  );
};

export default SessionRecapModal;
